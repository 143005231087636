import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import '../components/layout.css'
import { 
  Box
} from "@chakra-ui/react"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/linkResolver"

import Seo from "../Seo"
import SliceZone from '../components/SliceZone/SliceZone'

import PageHero from "../components/PageHero/PageHero"

const WhatWeTradePage = ({ data }) => {

  const document = data.allPrismicPage.edges[0].node.data;
  console.log(document)

  return (
    <Layout>
      <Seo title="About Us | Plateau Forest Products" />
      <PageHero 
        heroImage={document.hero_image?.localFile}
        alt={document.hero_image.alt}
        pageTitle={document.page_title.text}
        pageSubtitle={document.page_subtitle.text}
      />
        <Box>
          <SliceZone sliceZone={document.body}/>
        </Box>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicPage(filter: {uid: {eq: "about-us"}}) {
      edges {
        node {
          _previewable
          data {
            page_title {
              text
            }
            page_subtitle {
              text
            }
            hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            body {
              ... on PrismicPageDataBodyGreenCallToActionBlock {
                id
                slice_type
                primary {
                  block_color
                  yellow_title {
                    text
                  }
                  white_headline {
                    text
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyWhiteCallToActionBlock {
                id
                slice_type
                primary {
                  yellow_title {
                    text
                  }
                  white_headline {
                    text
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyWhatWeTradeBlock {
                id
                items {
                  short_description {
                    text
                  }
                  product_name {
                    text
                  }
                  product_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                    alt
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageDataBodyIndustryMembers {
                slice_type
                primary {
                  industry_partners {
                    document {
                      ... on PrismicIndustryPartners {
                        data {
                          industry_member {
                            industry_logo {
                              alt
                              copyright
                              url
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                            industry_link {
                              url
                              target
                            }
                          }
                        }
                      }
                    }
                  }
                }
                id
              }
              ... on PrismicPageDataBodyContentArea {
                id
                slice_type
                primary {
                  white_or_green_block_
                  content_title {
                    text
                  }
                  content {
                    raw
                  }
                }
              }
              ... on PrismicPageDataBodyCompanyOverviewBlock {
                id
                slice_type
                primary {
                  description {
                    text
                  }
                  block_title {
                    text
                  }
                }
                items {
                  brief_success_statement {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyTestimonials {
                id
                slice_type
                primary {
                  title {
                    text
                  }
                }
                items {
                  testimonial_text {
                    text
                  }
                  person_name {
                    text
                  }
                  company_name {
                    text
                  }
                }
                slice_label
              }
              ... on PrismicPageDataBodyQuoteBlock {
                id
                slice_type
                primary {
                  quote {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyMessageBlock {
                id
                slice_type
                primary {
                  title {
                    text
                  }
                  text_content {
                    raw
                  }
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`


export default withPrismicPreview(WhatWeTradePage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])